import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

import 'bootstrap'
import 'bootstrap-select/js/bootstrap-select'
import flatpickr from "flatpickr"

import Sortable from 'sortablejs'
window.Sortable = Sortable;

import c3 from 'c3';
global.c3 = c3;

require('@nathanvda/cocoon');

import 'javascript/plugins/rails_sortable';
