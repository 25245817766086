// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('../images', true)
const imagePath = (name) => images(name, true)

import 'javascript/core.js'
import 'tenant_parametrizations/core.js'
import 'javascript/answer_forms.coffee'
import 'javascript/admin/activities_quotas.coffee'
import 'javascript/admin/activities_target.coffee'
import 'javascript/admin/activities_target_activity.coffee'
import 'javascript/admin/lead_generation.coffee'
import 'javascript/admin/simulation_target.coffee'
import 'javascript/admin/stats.coffee'
import 'javascript/admin/topics.coffee'
import 'javascript/admin/copy_to_clipboard'
import 'javascript/admin/prizes.coffee'
